import React from "react";
import { Link } from "react-router-dom";
import { track } from "@vercel/analytics";

function HeroSection() {
  return (
    <section className="relative isolate overflow-hidden bg-gradient-to-r from-gray-800 via-gray-900 to-gray-800 pt-20 lg:pt-32 pb-16 flex items-center justify-center">
      <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8 text-center">
        <div className="space-y-10">
          {/* Text Section */}
          <div>
            <p className="text-base font-semibold leading-7 text-orange-500">
              #1 Medical Research Companion
            </p>
            <h1 className="mt-6 font-extrabold text-4xl md:text-5xl lg:text-5xl leading-tight text-gray-100">
              <span className="bg-gradient-to-r from-orange-400 to-red-600 bg-clip-text text-transparent">
                Streamline Your Scientific Research
              </span>
            </h1>
            <p className="mt-6 text-xl leading-8 text-gray-300 mx-auto max-w-3xl">
              Access over 26 million PubMed articles, ranked for relevance and
              quality. Discover trusted evidence, simplify your workflow, and
              elevate your research—all in one platform. Start for free today.
            </p>
            <div className="flex flex-col md:flex-row items-center justify-center gap-6 mt-10">
              <Link
                to="/signup"
                className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium rounded-lg group bg-gradient-to-br from-orange-500 to-red-500 group-hover:from-orange-400 group-hover:to-red-400 hover:text-white text-white focus:ring-4 focus:outline-none focus:ring-orange-500"
                onClick={() => track("Hero section: Get Started Clicked")}
              >
                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-gray-900 rounded-md group-hover:bg-opacity-0">
                  Get Started Free
                </span>
              </Link>
              <Link
                to="/pricing"
                className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium rounded-lg group bg-gradient-to-br from-orange-500 to-red-500 group-hover:from-orange-400 group-hover:to-red-400 hover:text-white text-white focus:ring-4 focus:outline-none focus:ring-orange-500"
                onClick={() => track("Hero Explore Plans Clicked")}
              >
                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-gray-900 rounded-md group-hover:bg-opacity-0">
                  Explore Our Plans
                </span>
              </Link>
            </div>
          </div>

          {/* Video Section */}
          <div>
            <video
              className="w-full max-w-4xl h-auto mx-auto rounded-xl shadow-xl ring-1 ring-gray-400/10"
              controls
              src="assets/cure-demo-vid.mp4"
              onClick={() => track("Hero Video Clicked")}
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
